// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-banner[data-v-478e1dc1]{margin-top:0}.advanced-setting[data-v-478e1dc1]{border:1px solid var(--border);padding:20px;border-radius:var(--border-radius)}.advanced-setting h1[data-v-478e1dc1]{font-size:14px}.advanced-setting h2[data-v-478e1dc1]{font-size:12px;margin-bottom:0;opacity:.8}.settings-value pre[data-v-478e1dc1]{margin:0}.header[data-v-478e1dc1]{display:flex;margin-bottom:20px}.title[data-v-478e1dc1]{flex:1}.modified[data-v-478e1dc1]{border:1px solid var(--primary);border-radius:5px}.experimental[data-v-478e1dc1],.modified[data-v-478e1dc1]{margin-left:10px;padding:2px 10px;font-size:12px}.experimental[data-v-478e1dc1]{border:1px solid var(--error);border-radius:5px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
