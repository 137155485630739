// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compat-matrix,.device-col{display:flex}.device-col{flex-direction:column;border-right:1px solid var(--border)}.device-col>*{border-bottom:1px solid var(--border)}.compat-cell{flex-basis:1em;padding:0 10px 0 10px}.compat-cell.has-device{background-color:var(--info-banner-bg)}.device-label,.node-label{display:flex;align-items:center;color:var(--input-label)}.node-label{padding:0 10px;justify-content:center}.blank-corner,.compat-cell,.device-label,.node-label{flex-basis:calc(2em + 10px)}.blank-corner{background:linear-gradient(to top right,#fff 0,#fff calc(50% - 1px),var(--body-text) 50%,#fff calc(50% + 1px),#fff 100%)}.blank-corner DIV.text-right{padding-left:80px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
