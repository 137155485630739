// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pool-headers[data-v-56625421],.pool-row[data-v-56625421]{display:grid;grid-column-gap:1.75%;margin-bottom:10px;align-items:center}.pool-headers.cidr[data-v-56625421],.pool-row.cidr[data-v-56625421]{grid-template-columns:41.75% 41.75% 15%}.pool-headers.range[data-v-56625421],.pool-row.range[data-v-56625421]{grid-template-columns:20% 20% 20% 20% 15%}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
