// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag[data-v-48fa62b5]{border:1px solid var(--primary);border-radius:var(--border-radius);box-sizing:border-box;margin:0;padding:0;font-size:14px;line-height:20px;list-style:none;display:inline-block;height:auto;margin-inline-end:8px;padding-inline:7px;white-space:nowrap;background:var(--accent-btn);opacity:1;text-align:start;color:var(--link);margin-bottom:10px;margin-right:8px;padding-top:8px;padding-bottom:8px}.tag i[data-v-48fa62b5],.tag.add[data-v-48fa62b5]{cursor:pointer}.tag.add[data-v-48fa62b5]{border-style:dashed}.tag.add[data-v-48fa62b5],.tag.input[data-v-48fa62b5]{background:var(--body-bg)}.tag.input[data-v-48fa62b5]{border:none;border-radius:none;padding:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
