// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combination-keys__container[data-v-571962f2]{max-width:60px}.combination-keys__container DIV[data-v-571962f2],.combination-keys__container SPAN[data-v-571962f2]{display:block;text-align:center}.combination-keys__container SPAN[data-v-571962f2]{border-radius:3px}.combination-keys__container SPAN.open[data-v-571962f2],.combination-keys__container SPAN[data-v-571962f2]:hover{color:var(--primary-hover-text);background:var(--primary-hover-bg)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
