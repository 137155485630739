// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-bar[data-v-7580f307]{display:flex;flex-direction:row;justify-content:space-between}.group-bar .right[data-v-7580f307]{margin-top:5px;margin-bottom:3px}.group-bar .group-tab[data-v-7580f307],.group-bar .group-tab[data-v-7580f307]:after{height:50px}.group-bar .group-tab[data-v-7580f307]:after{right:-20px}.group-bar .group-tab SPAN[data-v-7580f307]{color:var(--body-text)!important}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
