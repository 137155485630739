// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close[data-v-a2d41ecc]{top:10px;right:10px;padding:0;position:absolute}.disk[data-v-a2d41ecc]{position:relative}.disk .secret-name[data-v-a2d41ecc]{height:61px}.disk[data-v-a2d41ecc]:not(:last-of-type){padding-bottom:10px;margin-bottom:30px}.flex[data-v-a2d41ecc]{display:flex;justify-content:space-between}.badge-state[data-v-a2d41ecc]{padding:2px 5px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
