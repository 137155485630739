// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role[data-v-1900141c]{align-items:center;background-color:rgba(0,0,0,.05);border:1px solid var(--header-border);border-radius:5px;color:var(--tag-text);line-height:20px;padding:2px 5px;white-space:nowrap;display:inline-flex;margin-right:3px}.role-value.text-link-enabled[data-v-1900141c]{cursor:pointer}.role-value.text-link-enabled[data-v-1900141c]:hover{color:var(--primary)}.role-value+.icon-close[data-v-1900141c]{margin-left:3px;cursor:pointer}.role-value+.icon-close[data-v-1900141c]:hover{color:var(--primary)}.project-members[data-v-1900141c]  .group-bar{display:flex;justify-content:space-between}.cluster-add[data-v-1900141c]{justify-content:flex-end}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
