// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bundleModal .support-modal[data-v-fff1d752]{border-radius:var(--border-radius);max-height:100vh}.bundleModal .bundle[data-v-fff1d752]{cursor:pointer;color:var(--primary)}.bundleModal .icon-spinner[data-v-fff1d752]{font-size:100px}.bundleModal .content[data-v-fff1d752]{height:218px}.bundleModal .content .circle[data-v-fff1d752]{padding-top:20px;height:160px}.bundleModal div[data-v-fff1d752]{line-height:normal}.bundleModal .footer[data-v-fff1d752]{display:flex;justify-content:center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
