// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content[data-v-3b1f7a1f]{display:grid;grid-column-gap:20px;grid-row-gap:20px;grid-template-columns:70% 30%}.only-community[data-v-3b1f7a1f]{display:grid;grid-template-columns:100%}.community[data-v-3b1f7a1f]{border-left:1px solid var(--border);padding-left:20px}.community>h2[data-v-3b1f7a1f]{font-size:18px;font-weight:300;margin-bottom:20px}.community .support-link[data-v-3b1f7a1f]{margin:10px 0}.box[data-v-3b1f7a1f]{padding:20px;border:1px solid var(--border)}.box.box-primary[data-v-3b1f7a1f]{border-color:var(--primary)}.box>h2[data-v-3b1f7a1f]{font-size:20px;font-weight:300}.box>div[data-v-3b1f7a1f]{font-weight:300;line-height:18px;opacity:.8}.role-secondary[data-v-3b1f7a1f]:focus{background-color:transparent}.warning[data-v-3b1f7a1f]{margin:0 -5px 0 -5px;padding:5px;background-color:var(--warning-banner-bg)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
