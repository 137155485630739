// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header[data-v-f8e03ba0]{display:flex;justify-content:space-between;align-items:center}.footer[data-v-f8e03ba0]{width:100%;display:flex;justify-content:flex-end}.banner-icon[data-v-f8e03ba0]{display:flex;align-items:center}.banner-content[data-v-f8e03ba0]{display:flex}.banner-message[data-v-f8e03ba0]{display:flex;flex-direction:column;justify-content:center;padding:0 15px}.icon[data-v-f8e03ba0]{font-size:20px;width:20px;line-height:23px}.currentVersion[data-v-f8e03ba0]{margin-top:10px;display:flex;justify-content:space-between}.currentVersion .version[data-v-f8e03ba0]{font-size:16px;font-weight:700}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
