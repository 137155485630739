// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-root[data-v-2a811908]{display:flex;flex-direction:column;height:100vh}.dashboard-content[data-v-2a811908]{display:grid;flex-grow:1;grid-template-areas:"main";grid-template-columns:auto;grid-template-rows:auto}.logo[data-v-2a811908]{margin:200px 0 40px 0}.logo[data-v-2a811908],.tagline[data-v-2a811908]{display:flex;justify-content:center}.tagline[data-v-2a811908]{margin-top:30px}.tagline>div[data-v-2a811908]{font-size:16px;line-height:22px;max-width:80%;text-align:center}.description[data-v-2a811908]{font-size:24px!important;font-weight:400}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
