// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-example[data-v-3ba6e3d7]{display:flex;align-content:center}.link-example a[data-v-3ba6e3d7]{margin:auto}.logo-container[data-v-3ba6e3d7]{display:flex;flex-direction:column}.logo-container[data-v-3ba6e3d7] .simple-box{position:relative;flex:1;max-height:120px}.logo-container[data-v-3ba6e3d7] .simple-box .content{height:100%;display:flex}.logo-container[data-v-3ba6e3d7] .simple-box .logo-preview{max-width:100%}.logo-container LABEL[data-v-3ba6e3d7]{position:absolute;top:10px;left:10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
