// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-71318452]  .group-name{line-height:30px}[data-v-71318452]  .group-bar{display:flex;flex-direction:row;justify-content:space-between}[data-v-71318452]  .group-bar .right{display:flex;align-items:center}[data-v-71318452]  .group-bar .right .age{width:100px}[data-v-71318452]  .group-bar .right .actions{padding-right:7px}[data-v-71318452]  .group-bar.has-description .right{margin-top:5px}[data-v-71318452]  .group-bar.has-description .group-tab,[data-v-71318452]  .group-bar.has-description .group-tab:after{height:50px}[data-v-71318452]  .group-bar.has-description .group-tab:after{right:-20px}[data-v-71318452]  .group-bar.has-description .group-tab .description{margin-top:-20px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
