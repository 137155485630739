// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consoleBut[data-v-58288df1]{display:flex;justify-content:flex-end}.overview-basics[data-v-58288df1]{display:grid;grid-template-columns:100%;grid-template-rows:auto;grid-row-gap:15px}.overview-basics .badge-state[data-v-58288df1]{padding:2px 5px;font-size:12px;margin-right:3px}.overview-basics .smart-row[data-v-58288df1]{display:flex;flex-direction:row}.overview-basics .smart-row .console[data-v-58288df1]{display:flex;overflow:hidden}.overview-basics__name[data-v-58288df1]{flex:1}.overview-basics__ssh-key[data-v-58288df1]{min-width:150px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
